import React, { useState, useEffect } from "react";
import { styled, Box, Typography, Button } from "@mui/material";
import Worklist from "../../Assets/Icons/worlist.svg";
import Casebox from "../../Assets/Icons/casebox.svg";
import Slide from "../../Assets/Icons/slide.svg";
import Share from "../../Assets/Icons/share.svg";
import Courses from "../../Assets/Icons/courses.svg";
import Quiz from "../../Assets/Icons/quiz.svg";
import Groups from "../../Assets/Icons/groups.svg";
import "./style.css";
import WorkList from "../../Views/WorkList/WorkList";
import SlideArchive from "../../Views/SlideArchive/SlideArchive";
import Demo from "../../Views/Demo/Demo";
import MyCaseBox from "../../Views/CaseBox/MyCaseBox";
import ShareSlide from "../../Views/ShareSlide/ShareSlide";

const StyledContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
  minWidth: "16%",
  maxWidth: "16%",
  height: 720,
  borderRadius: "0px 12px 12px 0px",
  marginTop: "20px",
  borderImageSlice: 1,
  border: "2px solid transparent",
  "@media (max-width: 1540px)": {
    height: "720px",
    minWidth: "18%",
  },
});

const BoxContainer = styled(Button)({
  width: 196,
  height: 48,
  background: "rgba(255, 255, 255, 0.8)",
  borderRadius: "12px",
  display: "flex",
  alignItems: "left",
  marginTop: "40px",
  border: "none",
  outline: "none",
  justifyContent: "flex-start",
});

const BoxContainer1 = styled(Box)({
  width: "32px",
  height: "32px",
  borderRadius: "40px",
  display: "flex",
  alignItems: "left",
  justifyContent: "flex-start",
  margin: "8px 12px 8px 8px",
});

const StyledTypography = styled(Typography)({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#6C7C93",
});

const SidePanel = () => {
  const [selectedButton, setSelectedButton] = useState(() => {
    // Initialize state from localStorage or default to 1
    return parseInt(localStorage.getItem("selectedButton")) || 1;
  });

  useEffect(() => {
    // Update localStorage when selectedButton changes
    localStorage.setItem("selectedButton", selectedButton);
  }, [selectedButton]);

  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
  };

  const renderMenuItem = (buttonId, icon, text) => (
    <BoxContainer
      key={buttonId}
      variant="text"
      onClick={() => handleButtonClick(buttonId)}
      style={{
        background:
          selectedButton === buttonId
            ? "linear-gradient(360deg, #2279F5 8.33%, #00D2FE 91.67%)"
            : "rgba(255, 255, 255, 0.8)",
      }}
    >
      <BoxContainer1
        style={{
          background:
            selectedButton === buttonId
              ? "#FFFFFF"
              : "linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
        }}
      >
        <img className="img-icons" src={icon} alt={text} />
      </BoxContainer1>
      <StyledTypography
        sx={{
          color: selectedButton === buttonId ? "#ffffff" : "#6C7C93",
        }}
        variant="h6"
        component="h6"
      >
        {text}
      </StyledTypography>
    </BoxContainer>
  );

  const renderContent = () => {
    switch (selectedButton) {
      case 1:
        return <WorkList />;
      case 2:
        return <MyCaseBox />; // Replace with actual component when available
      case 3:
        return <SlideArchive />;
      case 4:
        return <Demo />;
      case 5:
        return <Demo />;
      case 6:
        return <Demo />;
      case 7:
        return <ShareSlide />;
      default:
        return <WorkList />;
    }
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <StyledContainer>
        {renderMenuItem(1, Worklist, "Worklist")}
        {renderMenuItem(2, Casebox, "My Casebox")}
        {renderMenuItem(3, Slide, "Slide Archive")}
        {/* {renderMenuItem(4, Courses, "Courses")} */}
        {/* {renderMenuItem(5, Quiz, "Quiz")} */}
        {/* {renderMenuItem(6, Groups, "Groups")} */}
        {renderMenuItem(7, Share, "Share Case")}
      </StyledContainer>
      <Box sx={{ flex: 1, width: "80%", padding: "20px 0px 20px 20px" }}>
        {renderContent()}
      </Box>
    </Box>
  );
};

export default SidePanel;
