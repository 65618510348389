import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const URLRedirectHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAndRedirect = () => {
      const currentURL = window.location.href;
      const urlParams = new URLSearchParams(window.location.search);
      const isPublic = urlParams.get("isPublic") == "True";

      // Check for casebox/share pattern
      if (currentURL.includes("casebox/share/")) {
        const shareId = currentURL.split("/casebox/share/")[1].split("&")[0];
        if (isPublic) {
          navigate(`/casebox-viewer?id=${shareId}`);
        } else {
          navigate(`/sharelogin?id=${shareId}&type=casebox`);
        }
        return;
      }

      // Check for standard share link
      if (currentURL.includes("/share/")) {
        const shareId = currentURL.split("/share/")[1];
        if (isPublic) {
          navigate(`/viewer?id=${shareId}`);
        } else {
          navigate(`/sharelogin?id=${shareId}`);
        }
        return;
      }

      // Check for reset password link
      if (currentURL.includes("/reset-password")) {
        const token = urlParams.get("token");
        if (token) {
          navigate("/reset-password", {
            state: {
              showResetPassword: true,
              resetToken: token,
            },
          });
        }
      }
    };

    checkAndRedirect();
  }, [navigate, location]);

  return null;
};

export default URLRedirectHandler;
