import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { DeleteOutline, EditOutlined, Search } from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
  Pagination,
  Stack,
  PaginationItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { API_URL } from "../../env";

const EditShareModal = ({ share, open, onClose, onSuccess }) => {
  const [expiryDate, setExpiryDate] = useState(
    share?.expiry_date?.split("T")[0] || ""
  );
  const [isPublic, setIsPublic] = useState(share?.is_public || false);
  const [isPasswordProtected, setIsPasswordProtected] = useState(
    share?.password_protected || false
  );
  const [password, setPassword] = useState("");
  const [sharedEmails, setSharedEmails] = useState(share?.shared_emails || []);
  const [newEmail, setNewEmail] = useState("");
  const [isEdit, setIsEdit] = useState(share?.is_edit || true);
  const [isActive, setIsActive] = useState(share?.is_active || true);

  const handleAddEmail = () => {
    if (newEmail && /\S+@\S+\.\S+/.test(newEmail)) {
      setSharedEmails([...sharedEmails, newEmail]);
      setNewEmail("");
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    setSharedEmails(sharedEmails.filter((email) => email !== emailToRemove));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation(); // Stop event propagation

    try {
      const response = await fetch(
        `${API_URL}combined_shares/shares/slide/${share.share_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            password: isPasswordProtected ? password : null,
            shared_emails: sharedEmails,
            expiry_date: new Date(expiryDate).toISOString(),
            is_public: isPublic,
            is_edit: isEdit,
            is_active: isActive,
            selected_images: [],
          }),
        }
      );

      if (response.ok) {
        Swal.fire("Success", "Share updated successfully", "success");
        onSuccess();
        onClose();
      } else {
        throw new Error("Failed to update share");
      }
    } catch (error) {
      console.error("Error updating share:", error);
      Swal.fire("Error", "Failed to update share", "error");
    }
  };
  const handleModalKeyDown = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          background: "linear-gradient(0deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
          borderRadius: "16px",
          padding: "20px",
          width: "50%",
        },
      }}
      onKeyDown={handleModalKeyDown}
      disableEscapeKeyDown={false}
    >
      <DialogTitle
        style={{
          color: "#2C3E50",
          fontWeight: 600,
          textAlign: "center",
          borderBottom: "2px solid rgba(255,255,255,0.3)",
          padding: "16px",
        }}
      >
        Edit Share
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} style={{ padding: "20px 0" }}>
          <div className="form-field">
            <label>Expiry Date</label>
            <input
              type="date"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
              required
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #BFD6F9",
                borderRadius: "8px",
                backgroundColor: "rgba(255,255,255,0.7)",
              }}
            />
          </div>

          <div className="form-field">
            <label style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
                style={{ marginRight: "8px" }}
              />
              Public Share
            </label>
          </div>

          <div className="form-field">
            <label style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={isPasswordProtected}
                onChange={(e) => setIsPasswordProtected(e.target.checked)}
                style={{ marginRight: "8px" }}
              />
              Password Protected
            </label>
          </div>

          {isPasswordProtected && (
            <div className="form-field">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required={isPasswordProtected}
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "1px solid #BFD6F9",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255,255,255,0.7)",
                }}
              />
            </div>
          )}

          <div className="form-field">
            <label style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={isEdit}
                onChange={(e) => setIsEdit(e.target.checked)}
                style={{ marginRight: "8px" }}
              />
              Allow Edit
            </label>
          </div>

          <div className="form-field">
            <label style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
                style={{ marginRight: "8px" }}
              />
              Is Active
            </label>
          </div>

          <div className="form-field">
            <label>Shared Emails</label>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "10px",
              }}
            >
              <input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Enter email address"
                style={{
                  flex: 1,
                  padding: "10px",
                  border: "1px solid #BFD6F9",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255,255,255,0.7)",
                }}
              />
              <Button
                onClick={handleAddEmail}
                variant="contained"
                style={{
                  backgroundColor: "#4B84F7",
                  color: "white",
                  textTransform: "none",
                }}
              >
                Add
              </Button>
            </div>
            <div
              style={{
                maxHeight: "100px",
                overflowY: "auto",
                backgroundColor: "rgba(255,255,255,0.5)",
                borderRadius: "8px",
                padding: "8px",
              }}
            >
              {sharedEmails.map((email, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "4px 8px",
                    marginBottom: "4px",
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                >
                  <span>{email}</span>
                  <IconButton
                    onClick={() => handleRemoveEmail(email)}
                    size="small"
                    style={{ padding: "4px" }}
                  >
                    <DeleteOutline
                      style={{ fontSize: "16px", color: "#FF6B6B" }}
                    />
                  </IconButton>
                </div>
              ))}
            </div>
          </div>

          <DialogActions style={{ padding: "20px 0 0 0" }}>
            <Button
              onClick={onClose}
              style={{
                color: "#4B84F7",
                backgroundColor: "white",
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#4B84F7",
                color: "white",
                textTransform: "none",
              }}
            >
              Save Changes
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const ShareSlide = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [shares, setShares] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentShare, setCurrentShare] = useState(null);

  const handleEditShare = (share) => {
    setCurrentShare(share);
    setEditModalOpen(true);
  };

  const handleDeleteShare = async (shareId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${API_URL}combined_shares/shares`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              share_ids: [shareId],
              share_type: "slide",
            }),
          });

          if (response.ok) {
            Swal.fire("Deleted!", "Share has been deleted.", "success");
            setRefresh(!refresh);
          } else {
            throw new Error("Failed to delete share");
          }
        } catch (error) {
          console.error("Error deleting share:", error);
          Swal.fire("Error", "Failed to delete share", "error");
        }
      }
    });
  };

  useEffect(() => {
    const fetchShares = async () => {
      try {
        const queryParams = new URLSearchParams({
          share_type: "slide",
          page: page,
          limit: 5,
        });

        if (searchTerm) {
          queryParams.append("search", searchTerm);
        }

        const response = await fetch(
          `${API_URL}combined_shares/shares?${queryParams}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch shares");
        }

        const data = await response.json();
        setShares(data.shares);
        setTotalPages(data.total_pages);
      } catch (error) {
        console.error("Error fetching shares:", error);
        Swal.fire({
          title: "Error",
          text: "Failed to fetch shares. Please try again.",
          icon: "error",
        });
      }
    };

    const debounceTimeout = setTimeout(() => {
      fetchShares();
    }, 300); // Debounce search by 300ms

    return () => clearTimeout(debounceTimeout);
  }, [page, searchTerm, fromDate, toDate, refresh]);

  const resetFilters = () => {
    setSearchTerm("");
    setFromDate("");
    setToDate("");
    setPage(1);
  };

  const formatDateToUserTimeZone = (utcDateString) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = toZonedTime(new Date(utcDateString), userTimeZone);
    return format(zonedDate, "MMMM do, yyyy h:mm:ss a");
  };

  return (
    <>
      <div className="worklist-container">
        <div className="worklist-header">
          <div className="header-title">Share Case</div>
          <div style={{ flexDirection: "row-reverse" }} className="filterBox">
            <div className="search-worklist">
              <input
                style={{ border: "0px" }}
                className="search-bar"
                type="text"
                placeholder="Search by resource name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div>
                <Search />
              </div>
            </div>
            {/* <div className="text-div1">
              From
              <input
                className="date-worklist"
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
            <div className="text-div2">
              To
              <input
                className="date-worklist"
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                style={{ marginLeft: "-20px" }}
              />
            </div>
            <Button
              onClick={resetFilters}
              style={{
                marginLeft: "10px",
                color: "#666",
                textTransform: "none",
              }}
            >
              Reset Filters
            </Button> */}
          </div>
        </div>

        <div className="table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    padding: "0px 0px 5px 0px",
                    fontSize: 12,
                    fontWeight: "600",
                    borderBottom: "none",
                  }}
                >
                  <Checkbox className="small-checkbox" disabled />
                </TableCell>
                <TableCell className="table-head">Resource Name</TableCell>
                <TableCell className="table-head">Shared By</TableCell>
                <TableCell className="table-head">Password Protected</TableCell>
                <TableCell className="table-head">View Count</TableCell>
                <TableCell className="table-head">Created At</TableCell>
                <TableCell className="table-head">Expiry Date</TableCell>
                <TableCell
                  style={{
                    padding: "0px 5px 5px 0px",
                    textAlign: "end",
                    fontSize: 12,
                    fontWeight: "600",
                    borderBottom: "none",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shares?.length > 0 ? (
                shares.map((row, index) => (
                  <React.Fragment key={row.share_id}>
                    <TableRow className="table-row">
                      <TableCell
                        style={{
                          borderTopLeftRadius: 12,
                          borderBottomLeftRadius: 12,
                        }}
                        className="table-cell"
                      >
                        <Checkbox className="small-checkbox" disabled />
                      </TableCell>
                      <TableCell className="table-cell">
                        {row.resource_name}
                      </TableCell>
                      <TableCell className="table-cell">
                        {row.shared_by_name}
                      </TableCell>
                      <TableCell className="table-cell">
                        {row.password_protected ? "Yes" : "No"}
                      </TableCell>
                      <TableCell className="table-cell">
                        {row.view_count}
                      </TableCell>
                      <TableCell className="table-cell">
                        {formatDateToUserTimeZone(row.created_at)}
                      </TableCell>
                      <TableCell className="table-cell">
                        {formatDateToUserTimeZone(row.expiry_date)}
                      </TableCell>
                      <TableCell
                        style={{
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          textAlign: "end",
                          width: 230,
                        }}
                        className="table-cell"
                      >
                        <IconButton
                          onClick={() => handleEditShare(row)}
                          className="iconButton"
                        >
                          <EditOutlined
                            style={{ fontSize: "16px", color: "grey" }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteShare(row.share_id)}
                          className="iconButton"
                        >
                          <DeleteOutline
                            style={{ fontSize: "16px", color: "grey" }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <div style={{ height: "4px" }}></div>
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    align="center"
                    style={{
                      padding: "50px",
                      textAlign: "center",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "35px",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      No Shares Found !
                    </div>
                    <div
                      style={{
                        fontSize: "19px",
                        color: "black",
                        marginTop: "10px",
                      }}
                    >
                      Please try adjusting your search filters.
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: "20px",
          }}
        >
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, value) => setPage(value)}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
          </Stack>
        </div>

        {editModalOpen && currentShare && (
          <EditShareModal
            share={currentShare}
            open={editModalOpen}
            onClose={() => {
              setEditModalOpen(false);
              setCurrentShare(null);
            }}
            onSuccess={() => setRefresh(!refresh)}
          />
        )}
      </div>
    </>
  );
};

export default ShareSlide;
