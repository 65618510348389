import React from "react";
import "./style.css";
import { API_URL } from "../../env";
import LogoSvg2 from "../../Assets/Ellipse 1.png";

function ProfileModal({ handleClose, modalPosition, userData }) {
  const handleLogout = async () => {
    try {
      const response = await fetch(`${API_URL}auth/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div
      className="profile-modal-overlay"
      onClick={(e) => {
        if (e.target.className === "profile-modal-overlay") {
          handleClose();
        }
      }}
    >
      <div
        className="profile-modal"
        style={{
          position: "absolute",
          zIndex: 2,
          top: `${modalPosition.top - 6}px`,
          left: `${modalPosition.left - 255}px`,
        }}
      >
        <div className="profile-header">
          <img className="avtimage1" src={LogoSvg2} alt="User avatar" />
          <div className="detail">
            <p className="name">{userData.FullName}</p>
            <p className="mail">{userData.Email}</p>
          </div>

          <div className="profile-buttons">
            {/*<button className="button1">Manage Profile</button>*/}
            <button className="button2" onClick={handleLogout}>
              Log Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileModal;
