import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import CaseModal from "../../Components/CaseModal/CaseModal";
import {
  AddCommentOutlined,
  Delete,
  DeleteOutline,
  EditOutlined,
  FlagOutlined,
  RemoveRedEyeOutlined,
  Search,
  VerifiedOutlined,
} from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
  Pagination,
  Stack,
  PaginationItem,
  Button,
} from "@mui/material";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { API_URL } from "../../env";
import ShareModal from "../../Components/Share/ShareModal";
import FlagModal from "../../Components/Flag/FlagModal";
import OpinionModal from "../../Components/Opinion/OpinionModal";
import DetailModal from "../../Components/DetailModal/DetailModal";
import SlideArchive from "../Slide/SlideArchive";

const SPECIALIZATIONS = [
  { value: "Cardiology", label: "Cardiology" },
  { value: "Neurology", label: "Neurology" },
  { value: "Orthopedics", label: "Orthopedics" },
  { value: "Cytology", label: "Cytology" },
  { value: "Histopathology", label: "Histopathology" },
  { value: "Hematology", label: "Hematology" },
  { value: "Clinical pathology", label: "Clinical pathology" },
  { value: "Molecular pathology", label: "Molecular pathology" },
  { value: "Microbiology", label: "Microbiology" },
  { value: "Biochemistry", label: "Biochemistry" },
  { value: "Veterinary pathology", label: "Veterinary pathology" },
];

const SlideArchives = () => {
  // State management
  const [searchTerm, setSearchTerm] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [cases, setCases] = useState([]);
  const [selectedCases, setSelectedCases] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isDeleteActive, setIsDeleteActive] = useState(false);

  // Modal states
  const [open, setOpen] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [isFlagModalOpen, setIsFlagModalOpen] = useState(false);
  const [isOpinionModalOpen, setIsOpinionModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);

  // Selected case states
  const [selectedCaseForFlag, setSelectedCaseForFlag] = useState(null);
  const [selectedCase, setSelectedCase] = useState(null);
  const [selectedCaseDetail, setSelectedCaseDetail] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [caseData, setCaseData] = useState(null);
  const [currentCaseId, setCurrentCaseId] = useState(null);

  const [openWorklist, setopenWorklist] = useState(false);
  console.log(openWorklist, currentCaseId, "openWorklist");

  const getPriorityLabel = (value) => {
    const styles = {
      1: {
        label: "Low",
        style: {
          color: "var(--Green-100, #47D273)",
          background: "var(--Green-5, #EBFAEF)",
        },
      },
      2: {
        label: "Medium",
        style: {
          color: "var(--Yellow-100, #FAC20A)",
          background: "var(--Yellow-5, #FEF9E6)",
        },
      },
      3: {
        label: "High",
        style: {
          color: "var(--Red-100, #FB3B42)",
          background: "var(--Red-5, #FEE6E7)",
        },
      },
    };

    const defaultStyle = {
      fontSize: "14px",
      fontWeight: 600,
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 68,
      height: 28,
    };

    const priority = styles[value] || { label: "Unknown", style: {} };
    return {
      label: priority.label,
      style: { ...defaultStyle, ...priority.style },
    };
  };

  const handleDeleteCase = async (caseId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${API_URL}cases/${caseId}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (response.ok) {
            Swal.fire("Deleted!", "Your case has been deleted.", "success");
            setRefresh(!refresh);
          }
        } catch (error) {
          console.error("Error deleting case:", error);
          Swal.fire("Error", "Failed to delete case", "error");
        }
      }
    });
  };

  const constructQueryString = () => {
    const params = new URLSearchParams();
    params.append("page", page);
    if (searchTerm) params.append("case_name", searchTerm);
    if (specialization) params.append("specialization", specialization);
    if (fromDate) params.append("date_from", fromDate);
    if (toDate) params.append("date_to", toDate);
    return params.toString();
  };

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const queryString = constructQueryString();
        const response = await fetch(
          `${API_URL}cases/archived?${queryString}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch cases");

        const data = await response.json();
        setCases(data.cases);
        setTotalPages(data.total_pages);
      } catch (error) {
        console.error("Error fetching cases:", error);
        Swal.fire({
          title: "Error",
          text: "Failed to fetch cases. Please try again.",
          icon: "error",
        });
      }
    };

    fetchCases();
  }, [page, searchTerm, specialization, fromDate, toDate, refresh]);

  const resetFilters = () => {
    setSearchTerm("");
    setSpecialization("");
    setFromDate("");
    setToDate("");
    setPage(1);
  };

  const formatDateToUserTimeZone = (utcDateString) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = toZonedTime(utcDateString, userTimeZone);
    return format(zonedDate, "MMMM do, yyyy h:mm:ss a");
  };

  const truncateText = (text, limit) => {
    return text?.length > limit ? `${text.slice(0, limit)}...` : text;
  };

  // Modal handlers
  const handleFlagClose = () => {
    setIsFlagModalOpen(false);
    setSelectedCaseForFlag(null);
  };

  const handleFlagOpen = (caseId) => {
    const caseToOpen = cases.find((c) => c.CaseId === caseId);
    if (caseToOpen) {
      setSelectedCaseForFlag(caseToOpen);
      setIsFlagModalOpen(true);
    }
  };

  const handleOpinionClose = () => {
    setIsOpinionModalOpen(false);
    setSelectedCase(null);
  };

  const handleDetailOpen = (caseId) => {
    const caseToOpen = cases.find((c) => c.CaseId === caseId);
    if (caseToOpen) {
      setSelectedCaseDetail(caseToOpen);
      setIsDetailModalOpen(true);
    }
  };

  const handleDetailClose = () => {
    setSelectedCaseDetail(null);
    setIsDetailModalOpen(false);
  };
  const handleArchiveToggle = async (caseData) => {
    try {
      const updatedData = {
        CaseName: caseData.CaseName,
        CaseDescription: caseData.CaseDescription,
        HospitalId: caseData.HospitalId,
        HospitalName: caseData.HospitalName,
        CaseNo: caseData.CaseNo,
        Specialization: caseData.Specialization,
        Site: caseData.Site,
        PatientName: caseData.PatientName,
        Age: caseData.Age,
        Gender: caseData.Gender,
        PatientStrId: caseData.PatientStrId,
        MedicalHistory: caseData.MedicalHistory,
        Slides: caseData.Slides,
        FileSize: caseData.FileSize,
        AssignTo: caseData.AssignTo,
        Priority: caseData.Priority,
        Status: caseData.Status,
        ArchivalStatus: !caseData.ArchivalStatus,
      };

      console.log("Sending updated data:", updatedData); // Add this to debug

      const response = await fetch(`${API_URL}cases/${caseData.CaseId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        setRefresh(!refresh);
        Swal.fire({
          title: "Success!",
          text: `Case ${
            !caseData.ArchivalStatus ? "archived" : "unarchived"
          } successfully`,
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
      } else {
        const errorData = await response.json();
        console.error("Error response:", errorData); // Add this to debug
        throw new Error("Failed to update archive status");
      }
    } catch (error) {
      console.error("Error updating archive status:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to update archive status",
        icon: "error",
        confirmButtonColor: "#3085d6",
      });
    }
  };

  const handleOpenEdit = (caseId) => {
    setIsEditMode(true);
    const selectedCase = cases.find((item) => item.CaseId === caseId);
    setCaseData(selectedCase);
    setOpen(true);
  };

  return (
    <>
      {openWorklist ? (
        <SlideArchive
          setopenWorklist={setopenWorklist}
          openWorklist={openWorklist}
          currentCaseId={currentCaseId}
        />
      ) : (
        <div className="worklist-container">
          <div className="worklist-header">
            <div className="header-title">Slide Archive</div>
            <div className="filterBox">
              <div className="search-worklist">
                <input
                  className="search-bar"
                  type="text"
                  placeholder="Search by hospital no./biopsy no./SI no."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ border: "0px" }}
                />
                <div>
                  <Search />
                </div>
              </div>
              <div className="text-div1">
                From
                <input
                  className="date-worklist"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="text-div2">
                To
                <input
                  className="date-worklist"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  style={{ marginLeft: "-20px" }}
                />
              </div>
              <div>
                <select
                  className="specialization"
                  value={specialization}
                  onChange={(e) => setSpecialization(e.target.value)}
                >
                  <option value="">Specialization</option>
                  {SPECIALIZATIONS.map((spec) => (
                    <option key={spec.value} value={spec.value}>
                      {spec.label}
                    </option>
                  ))}
                </select>
              </div>
              <Button
                onClick={resetFilters}
                style={{
                  marginLeft: "10px",
                  color: "#666",
                  textTransform: "none",
                }}
              >
                Reset Filters
              </Button>
            </div>
          </div>

          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      padding: "0px 0px 5px 0px",
                      fontSize: 12,
                      fontWeight: "600",
                      borderBottom: "none",
                    }}
                  >
                    <Checkbox
                      className="small-checkbox"
                      indeterminate={
                        selectedCases.length > 0 &&
                        selectedCases.length < cases.length
                      }
                      checked={
                        cases.length > 0 &&
                        selectedCases.length === cases.length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedCases(
                            cases?.map((caseItem) => caseItem.CaseId)
                          );
                          setIsDeleteActive(true);
                        } else {
                          setSelectedCases([]);
                          setIsDeleteActive(false);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell className="table-head">Sl No</TableCell>
                  <TableCell className="table-head">Hospital Name</TableCell>
                  <TableCell className="table-head">Case Name</TableCell>
                  <TableCell className="table-head">Specialization</TableCell>
                  <TableCell className="table-head">Patient Details</TableCell>
                  <TableCell className="table-head">Date</TableCell>
                  <TableCell className="table-head">Total Files</TableCell>
                  <TableCell className="table-head">Total Size</TableCell>
                  <TableCell className="table-head">Priority</TableCell>
                  <TableCell
                    style={{
                      padding: "0px 5px 5px 0px",
                      textAlign: "end",
                      fontSize: 12,
                      fontWeight: "600",
                      borderBottom: "none",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cases?.length > 0 ? (
                  cases.map((row, index) => (
                    <React.Fragment key={row.CaseId}>
                      <TableRow
                        onDoubleClick={() => {
                          setCurrentCaseId(row.CaseId);
                          setopenWorklist(true);
                        }}
                        className="table-row"
                      >
                        <TableCell
                          style={{
                            borderTopLeftRadius: 12,
                            borderBottomLeftRadius: 12,
                          }}
                          className="table-cell"
                        >
                          <Checkbox
                            className="small-checkbox"
                            checked={selectedCases.includes(row.CaseId)}
                            onChange={() => {
                              setSelectedCases((prev) => {
                                const newSelection = prev.includes(row.CaseId)
                                  ? prev.filter((id) => id !== row.CaseId)
                                  : [...prev, row.CaseId];
                                setIsDeleteActive(newSelection.length > 0);
                                return newSelection;
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell className="table-cell">
                          {index + 1 + (page - 1) * 5}
                        </TableCell>
                        <TableCell
                          className="table-cell"
                          style={{ overflow: "hidden" }}
                        >
                          {truncateText(row.HospitalName, 10)}
                        </TableCell>
                        <TableCell className="table-cell">
                          {truncateText(row.CaseName, 10)}
                        </TableCell>
                        <TableCell className="table-cell">
                          {truncateText(row.Specialization, 15)}
                        </TableCell>
                        <TableCell className="table-cell">
                          <div>
                            <span className="details">Name</span>:{" "}
                            {truncateText(row.PatientName, 15)}
                          </div>
                          <div>
                            <span className="details">Age:</span> {row.Age}
                          </div>
                          <div>
                            <span className="details">Gender:</span>{" "}
                            {row.Gender}
                          </div>
                          <div>
                            <span className="details">Id:</span>{" "}
                            {truncateText(row.PatientStrId, 10)}
                          </div>
                        </TableCell>
                        <TableCell className="table-cell">
                          {formatDateToUserTimeZone(row.CreatedAt)}
                        </TableCell>
                        <TableCell className="table-cell">
                          {row.Slides}
                        </TableCell>
                        <TableCell className="table-cell">
                          {parseFloat(row.FileSize).toFixed(2) + " Mb"}
                        </TableCell>
                        <TableCell className="table-cell">
                          <div
                            className="priority-level"
                            style={
                              getPriorityLabel(row.Priority.toString()).style
                            }
                          >
                            {getPriorityLabel(row.Priority.toString()).label}
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            borderTopRightRadius: 12,
                            borderBottomRightRadius: 12,
                            textAlign: "end",
                            width: 230,
                          }}
                          className="table-cell"
                        >
                          <IconButton
                            className="iconButton"
                            onClick={() => handleArchiveToggle(row)} // Pass the entire row object instead of just CaseId and ArchivalStatus
                            title={row.ArchivalStatus ? "Unarchive" : "Archive"}
                          >
                            <Button
                              variant="outlined"
                              size="small"
                              style={{
                                marginRight: "8px",
                                textTransform: "none",
                              }}
                            >
                              {row.ArchivalStatus ? "Unarchive" : "Archive"}
                            </Button>
                          </IconButton>
                          <IconButton
                            className="iconButton"
                            onClick={() => handleDetailOpen(row.CaseId)}
                          >
                            <RemoveRedEyeOutlined
                              className="removeRedEyeIcon"
                              style={{ fontSize: "16px", color: "grey" }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handleOpenEdit(row.CaseId)}
                            className="iconButton"
                          >
                            <EditOutlined
                              className="editIcon"
                              style={{ fontSize: "16px", color: "grey" }}
                            />
                          </IconButton>
                          <IconButton
                            className="iconButton"
                            onClick={() => handleDeleteCase(row.CaseId)}
                          >
                            <DeleteOutline
                              className="deleteIcon"
                              style={{ fontSize: "16px", color: "grey" }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <div style={{ height: "4px" }}></div>
                    </React.Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={11}
                      align="center"
                      style={{
                        padding: "50px",
                        textAlign: "center",
                        backgroundColor: "#f9f9f9",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "35px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        No Cases Found !
                      </div>
                      <div
                        style={{
                          fontSize: "19px",
                          color: "black",
                          marginTop: "10px",
                        }}
                      >
                        Please add new cases or try refreshing the page.
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>

          {/* Modals */}
          {open && (
            <CaseModal
              refresh={refresh}
              setRefresh={setRefresh}
              open={open}
              setOpen={setOpen}
              isEditMode={isEditMode}
              caseData={caseData}
              setIsEditMode={setIsEditMode}
            />
          )}

          {shareModal && (
            <ShareModal
              onClose={() => setShareModal(false)}
              shareModal={shareModal}
            />
          )}

          {isFlagModalOpen && selectedCaseForFlag && (
            <FlagModal
              isOpen={isFlagModalOpen}
              onClose={handleFlagClose}
              caseId={selectedCaseForFlag.CaseId}
              flagReason={selectedCaseForFlag.flag_reason || ""}
              onSuccess={() => setRefresh(!refresh)}
            />
          )}

          {isOpinionModalOpen && selectedCase && (
            <OpinionModal
              isOpen={isOpinionModalOpen}
              onClose={handleOpinionClose}
              caseId={selectedCase.CaseId}
              opinion={selectedCase.opinion || ""}
              onSuccess={() => setRefresh(!refresh)}
            />
          )}

          {isDetailModalOpen && selectedCaseDetail && (
            <DetailModal
              isOpen={isDetailModalOpen}
              onClose={handleDetailClose}
              caseData={selectedCaseDetail}
              onSuccess={() => setRefresh(!refresh)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SlideArchives;
