import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
  Chip,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./MyCaseBox.css";

// Styled components
const HeaderWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ImageCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
  },
}));

const CaseboxViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { caseData, caseType } = location.state || {};

  // Redirect if no data
  if (!caseData) {
    navigate("/");
    return null;
  }

  const handleViewSlide = (slideData) => {
    navigate("/viewer", {
      state: {
        annotationUrl: slideData.annotation_url,
        viewerUrl: slideData.viewer_url,
        slideName: slideData.slide_name,
        isEditMode: caseData.is_edit ?? false,
      },
    });
    console.log(slideData);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Header */}
      <HeaderWrapper>
        <Box>
          <Typography
            variant="h4"
            component="h1"
            fontWeight="bold"
            color="text.primary"
          >
            {caseData.casebox_name}
          </Typography>
        </Box>
      </HeaderWrapper>

      {/* Image Grid */}
      <Grid container spacing={3}>
        {caseData.shared_images?.map((image) => (
          <Grid item xs={12} md={6} lg={4} key={image.casebox_image_id}>
            <ImageCard elevation={2}>
              <CardMedia
                className="casebox-case"
                component="img"
                image={`https://path-image.s3.amazonaws.com/${image.thumbnail}`}
                alt={image.slide_name}
                sx={{ objectFit: "cover" }}
              />
              <CardContent className="card-content">
                <Typography variant="h6" gutterBottom>
                  {image.slide_name}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Slide ID: {image.slide_id}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Image ID: {image.casebox_image_id}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleViewSlide(image)}
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    View Slide
                  </Button>
                </Box>
              </CardContent>
            </ImageCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CaseboxViewer;
