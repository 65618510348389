import React, { useState } from "react";
import LoginImg from "../../Assets/Rectangle 15.png";
import LoginImg1 from "../../Assets/Rectangle 15 (1).png";
import LogoSvg from "../../Assets/logo.svg";
import LogoSvg1 from "../../Assets/logo1.svg";
import "./style.css";
import Checkbox from "@mui/material/Checkbox";
import { IconButton, InputAdornment, Box, Tabs, Tab } from "@mui/material";
import {
  Password,
  VisibilityOutlined,
  VisibilityOffOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../env";

const CreateAccount = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    organization: "",
    email: "",
    phone: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState(0);
  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const validateForm = () => {
    let validationErrors = {};
    const { email, phone, password, confirmPassword } = formData;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email || !emailRegex.test(email)) {
      validationErrors.email = "Please enter a valid email.";
    }
    const phoneRegex = /^\d{10}$/;
    if (!phone || !phoneRegex.test(phone)) {
      validationErrors.phone = "Please enter a valid 10-digit phone number.";
    }
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    if (!password || !passwordRegex.test(password)) {
      validationErrors.password =
        "Password must be at least 8 characters long, contain an uppercase letter, a number, and a special character.";
    }
    if (password !== confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match.";
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    const url = `${API_URL}auth/create-account`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert("Account created successfully!");
      navigate("/login");
    } else {
      console.error("Account creation failed");
    }
  };

  return (
    <div className="login-main">
      <div className="login-img">
        <img src={LoginImg1} alt="Create Account" className="image-desktop" />
        <img src={LoginImg} alt="Create Account" className="image" />
      </div>
      <div className="login-div">
        <div className="welcome-div">
          <div className="welcome-container">
            <div className="welcome-head">Welcome to</div>
            <div className="image-div" style={{ marginBottom: "-19px" }}>
              <div className="welcome-head1">
                <img src={LogoSvg} alt="Logo" />
              </div>
              <div className="welcome-head1">
                <img src={LogoSvg1} alt="Logo" />
              </div>
            </div>
          </div>
          <div className="contain12">
            <Box sx={{ width: "100%" }}>
              <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange1}
                centered
              >
                <Tab
                  label="Organization"
                  sx={{
                    background:
                      value === 0
                        ? "linear-gradient(to right, #c1e1ec, #bfefff)"
                        : "transparent",
                    color: value === 0 ? "white" : "black",
                    borderRadius: "12px 0px 0px 0px",
                    transition: "all 0.3s ease",
                  }}
                />
                <Tab
                  label="User"
                  sx={{
                    background:
                      value === 1
                        ? "linear-gradient(to right, #c1e1ec, #bfefff)"
                        : "transparent",
                    color: value === 1 ? "white" : "black",
                    borderRadius: "0px 12px 0px 0px",
                    transition: "all 0.3s ease",
                  }}
                />
              </Tabs>
            </Box>
            <div className="contain1">
              <h2 className="txt8-login" style={{ marginTop: "-20px" }}>
                Create Account
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="in-box-login">
                  <div
                    className="in-box"
                    style={{
                      maxHeight: "280px",
                      overflowY: "auto",
                      paddingRight: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "24px",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <label
                          className="label"
                          style={{ display: "block", marginBottom: "5px" }}
                        >
                          First Name
                        </label>
                        <input
                          className="inpt-login"
                          name="firstName"
                          type="text"
                          value={formData.firstName}
                          onChange={handleChange}
                          required
                          style={{
                            borderRadius: 12,
                            width: "100%",
                            padding: "10px",
                          }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <label
                          className="label"
                          style={{ display: "block", marginBottom: "5px" }}
                        >
                          Last Name
                        </label>
                        <input
                          className="inpt-login"
                          name="lastName"
                          type="text"
                          value={formData.lastName}
                          onChange={handleChange}
                          required
                          style={{
                            borderRadius: 12,
                            width: "100%",
                            padding: "10px",
                          }}
                        />
                      </div>
                    </div>

                    <div style={{ marginTop: 10 }}>
                      <label className="label">
                        {" "}
                        {value === 0
                          ? "Organization Name"
                          : "Organization Name (optional)"}
                      </label>
                      <input
                        className="inpt-login"
                        name="organization"
                        type="text"
                        value={formData.organization}
                        onChange={handleChange}
                        required={value === 0}
                        style={{
                          borderRadius: 12,
                          width: "100%",
                          padding: "10px",
                        }}
                      />
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <label className="label">Email</label>
                      <input
                        className="inpt-login"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        style={{
                          borderRadius: 12,
                          width: "100%",
                          padding: "10px",
                        }}
                      />
                      {errors.email && (
                        <div
                          className="error-message"
                          style={{ marginTop: "15px" }}
                        >
                          {errors.email}
                        </div>
                      )}
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <label className="label">Phone Number(Optional)</label>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <select
                          name="countryCode"
                          value={formData.countryCode}
                          onChange={handleChange}
                          required
                          style={{
                            borderRadius: "12px 0 0 12px",
                            padding: "10px",
                            border: "1px solid #c4cbd4",
                            borderRight: "none",
                            outline: "none",
                            width: "auto",
                            height: "42px",
                            color: "var(--Neutral-50, #6C7C93)",
                          }}
                        >
                          <option value="+91">+91</option>
                          <option value="+1">+1</option>
                          <option value="+44">+44</option>
                          <option value="+61">+61</option>
                          <option value="+81">+81</option>
                        </select>
                        <input
                          className="inpt-login1"
                          name="phone"
                          type="text"
                          value={formData.phone}
                          onChange={handleChange}
                          style={{
                            width: "100%",
                            padding: "10px",
                            borderLeft: "none",
                          }}
                        />
                        {errors.phone && (
                          <div
                            className="error-message"
                            style={{ marginTop: "15px" }}
                          >
                            {errors.phone}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "24px",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{ flex: 1, marginTop: 10, position: "relative" }}
                      >
                        <label className="label">Password</label>
                        <input
                          className="inpt-login"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          value={formData.password}
                          onChange={handleChange}
                          required
                          style={{
                            borderRadius: 12,
                            width: "100%",
                            padding: "10px",
                            boxSizing: "border-box",
                          }}
                        />
                        {errors.password && (
                          <div
                            className="error-message"
                            style={{ marginTop: "8px", color: "red" }}
                          >
                            {errors.password}
                          </div>
                        )}
                      </div>

                      <div
                        style={{ flex: 1, marginTop: 10, position: "relative" }}
                      >
                        <label className="label">Confirm Password</label>
                        <input
                          className="inpt-login"
                          name="confirmPassword"
                          type={showPassword ? "text" : "password"}
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          required
                          style={{
                            borderRadius: 12,
                            width: "100%",
                            padding: "10px",
                            boxSizing: "border-box",
                          }}
                        />
                        <button
                          type="button"
                          onClick={handleTogglePasswordVisibility}
                          style={{
                            position: "absolute",
                            right: 3,
                            top: "70%",
                            transform: "translateY(-50%)",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          {showPassword ? (
                            <VisibilityOffOutlined
                              style={{
                                color: "#2279F5",
                                hight: "18px",
                                width: "18px",
                              }}
                            />
                          ) : (
                            <VisibilityOutlined
                              style={{
                                color: "#2279F5",
                                hight: "18px",
                                width: "18px",
                              }}
                            />
                          )}
                        </button>
                        {errors.confirmPassword && (
                          <div
                            className="error-message"
                            style={{ marginTop: "8px", color: "red" }}
                          >
                            {errors.confirmPassword}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      fontSize: "small",
                      color: "var(--Neutral-50, #6C7C93)",
                    }}
                  >
                    <Checkbox
                      className="checkbox"
                      style={{
                        border: "0px",
                        borderRadius: "4px",
                        opacity: "0.6",
                        marginLeft: "-9px",
                      }}
                    />
                    By signing up, I agree to{" "}
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue" }}
                    >
                      Terms & Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue" }}
                    >
                      Privacy Policy
                    </a>
                    .
                  </div>

                  <div className="auth-container" style={{ marginTop: 20 }}>
                    <button type="submit" className="btn1-create-account">
                      Create Account
                    </button>
                    <span
                      className="or-divider"
                      style={{ marginBottom: "20px" }}
                    >
                      or
                    </span>
                    <button
                      type="button"
                      className="btn1-login1"
                      style={{ backgroundColor: "white" }}
                      onClick={() => navigate("/")}
                    >
                      Log In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
