import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import "./style.css";
import { API_URL } from "../../env";

const ShareLogin = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const shareId = searchParams.get("id");
  const isPublic = searchParams.get("isPublic") == "True";
  const shareType = searchParams.get("type");
  const isCaseboxShare =
    shareType === "casebox" || location.pathname.includes("/casebox/share");

  useEffect(() => {
    const initializeShare = async () => {
      console.log("Initializing share with ID:", shareId);

      if (!shareId) {
        console.log("No shareId found, navigating to home");
        navigate("/");
        return;
      }

      if (isPublic) {
        console.log("Public share detected, fetching share data");
        await fetchPublicShare();
      }
    };

    initializeShare();
  }, [shareId, isPublic]);

  const getApiEndpoint = (id) => {
    const baseEndpoint = isCaseboxShare ? "casebox_share/share" : "share/share";
    const endpoint = `${API_URL}${baseEndpoint}/${id}`;
    console.log("Generated API endpoint:", endpoint);
    return endpoint;
  };

  const fetchPublicShare = async () => {
    try {
      console.log("Fetching public share data...");
      setLoading(true);
      const apiEndpoint = getApiEndpoint(shareId);

      const response = await fetch(apiEndpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(
          response.status === 404
            ? "Share not found"
            : `Server error: ${response.status}`
        );
      }

      const data = await response.json();
      console.log("Received share data:", {
        isCaseboxShare,
        hasSharedImages: !!data?.shared_images,
        hasAnnotationUrl: !!data?.annotation_url,
        hasViewerUrl: !!data?.viewer_url,
      });

      if (isCaseboxShare) {
        if (!data?.shared_images || !Array.isArray(data.shared_images)) {
          throw new Error("Invalid response: Missing case data");
        }

        console.log("Navigating to casebox viewer");
        localStorage.setItem("caseData", JSON.stringify(data));
        navigate("/casebox-viewer", {
          state: { caseData: data },
        });
      } else {
        if (!data?.annotation_url || !data?.viewer_url) {
          throw new Error("Invalid response: Missing required viewer data");
        }

        console.log("Navigating to standard viewer");
        localStorage.setItem("annotationUrl", data.annotation_url);
        localStorage.setItem("viewerUrl", data.viewer_url);
        localStorage.setItem("slideName", data.slide_name || "Untitled");
        localStorage.setItem("isEditMode", (data.is_edit ?? false).toString());

        navigate("/viewer", {
          state: {
            annotationUrl: data.annotation_url,
            viewerUrl: data.viewer_url,
            slideName: data.slide_name || "Untitled",
            isEditMode: data.is_edit ?? false,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching public share:", error);
      setError(error.message || "An error occurred while fetching the share.");
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Handling private share submission");
    setError("");
    setLoading(true);

    try {
      if (!shareId) {
        throw new Error("Invalid share link");
      }

      const apiEndpoint = getApiEndpoint(shareId);
      const url = new URL(apiEndpoint);
      url.searchParams.append("password", password);
      url.searchParams.append("preview", false);

      console.log("Fetching private share data...");
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error("Invalid password");
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Received private share data:", {
        isCaseboxShare,
        hasSharedImages: !!data?.shared_images,
        hasAnnotationUrl: !!data?.annotation_url,
        hasViewerUrl: !!data?.viewer_url,
      });

      if (isCaseboxShare) {
        console.log("Navigating to private casebox viewer");
        localStorage.setItem("caseData", JSON.stringify(data));
        navigate("/casebox-viewer", {
          state: { caseData: data },
        });
      } else {
        console.log("Navigating to private standard viewer");
        localStorage.setItem("annotationUrl", data.annotation_url);
        localStorage.setItem("viewerUrl", data.viewer_url);
        localStorage.setItem("slideName", data.slide_name || "Untitled");
        localStorage.setItem("isEditMode", (data.is_edit ?? false).toString());

        navigate("/viewer", {
          state: {
            annotationUrl: data.annotation_url,
            viewerUrl: data.viewer_url,
            slideName: data.slide_name || "Untitled",
            isEditMode: data.is_edit ?? false,
          },
        });
      }
    } catch (err) {
      console.error("Error handling private share:", err);
      setError(err.message || "An error occurred while validating the share.");
    } finally {
      setLoading(false);
    }
  };

  // Rest of the component remains the same...
  return (
    <div className="login-container">
      <div className="login-card">
        <h1 className="login-title">
          {isCaseboxShare ? "Casebox Secure Access" : "Secure Access"}
        </h1>

        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              className="password-input"
              disabled={loading}
              required
              autoFocus
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          <button
            type="submit"
            className="login-button"
            disabled={loading || !password.trim()}
          >
            {loading ? "Validating..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ShareLogin;
