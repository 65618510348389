import React, { useState, useEffect } from "react";
import ColorPicker from "./ColorPicker";
import {
  RectangleOutlined,
  Grid4x4Outlined,
  DeleteOutline,
  PolylineOutlined,
  PhotoCamera,
  RotateLeftOutlined,
  RotateRightOutlined,
  Gesture,
} from "@mui/icons-material";
import { Circle, Minus, Maximize2, Minimize2, ArrowLeft } from "lucide-react";

const ToolButton = ({ onClick, title, selected, children }) => (
  <div className="tool-button-wrapper">
    <div
      className={`tool-button ${selected ? "selected" : ""}`}
      onClick={onClick}
    >
      {children}
    </div>
    <div className="tooltip">{title}</div>
  </div>
);

const MicroscopeToolbar = ({
  setCurrentTool,
  currentTool,
  openWorkspace,
  setOpenWorkspace,
  lineWidth,
  setLineWidth,
  drawColor,
  setDrawColor,
  rotation,
  handlePreciseRotation,
  isFullscreen,
  onSnapshot,
  onToggleGrid,
  onClearAnnotations,
  onToggleFullscreen,
  onRotateLeft,
  onRotateRight,
}) => {
  const [rotationInput, setRotationInput] = useState(rotation.toString());

  useEffect(() => {
    setRotationInput(rotation.toString());
  }, [rotation]);

  const normalizeRotation = (value) => {
    return ((value % 360) + 360) % 360;
  };

  const handleRotationChange = (e) => {
    const value = e.target.value;
    setRotationInput(value);

    const numValue = parseInt(value);
    if (!isNaN(numValue)) {
      const normalizedCurrent = normalizeRotation(rotation);
      const normalizedNew = normalizeRotation(numValue);
      const degrees = normalizedNew - normalizedCurrent;
      handlePreciseRotation(degrees);
    }
  };

  const handleRotationBlur = () => {
    let validValue = parseInt(rotationInput);

    if (isNaN(validValue)) {
      validValue = rotation;
    }

    validValue = normalizeRotation(validValue);
    setRotationInput(validValue.toString());

    const degrees = validValue - normalizeRotation(rotation);
    handlePreciseRotation(degrees);
  };

  const handleKeyDown = (e) => {
    if (e.key === "-" || e.keyCode === 189) {
      e.preventDefault();
    }
  };

  const handleRotateLeft = () => {
    const newRotation = normalizeRotation(rotation - 90);
    onRotateLeft();
    setRotationInput(newRotation.toString());
  };

  const handleRotateRight = () => {
    const newRotation = normalizeRotation(rotation + 90);
    onRotateRight();
    setRotationInput(newRotation.toString());
  };

  const handleToolClick = (tool) => {
    if (currentTool === tool) {
      setCurrentTool(null);
    } else {
      setCurrentTool(tool);
    }
  };

  return (
    <div className="toolbar">
      <div className="toolbar-group">
        <ToolButton
          onClick={() => setOpenWorkspace(!openWorkspace)}
          title="Toggle Workspace"
        >
          <ArrowLeft />
        </ToolButton>
      </div>

      <div className="toolbar-separator" />

      <div className="toolbar-group">
        <ToolButton
          onClick={() => handleToolClick("rect")}
          selected={currentTool === "rect"}
          title="Rectangle Tool"
        >
          <RectangleOutlined />
        </ToolButton>
        <ToolButton
          onClick={() => handleToolClick("circle")}
          selected={currentTool === "circle"}
          title="Circle Tool"
        >
          <Circle />
        </ToolButton>
        <ToolButton
          onClick={() => handleToolClick("freehand")}
          selected={currentTool === "freehand"}
          title="Brush Tool"
        >
          <Gesture />
        </ToolButton>
        <ToolButton
          onClick={() => handleToolClick("line")}
          selected={currentTool === "line"}
          title="Line Tool"
        >
          <Minus />
        </ToolButton>
        <ToolButton
          onClick={() => handleToolClick("polygon")}
          selected={currentTool === "polygon"}
          title="Polygon Tool"
        >
          <PolylineOutlined />
        </ToolButton>
      </div>

      <div className="toolbar-separator" />

      <div className="toolbar-group">
        <div className="style-control">
          <label style={{ color: "white" }}>Width:</label>
          <select
            value={lineWidth}
            onChange={(e) => setLineWidth(e.target.value)}
          >
            <option value="1">Extra thin</option>
            <option value="2">Thin</option>
            <option value="3">Normal</option>
            <option value="4">Thick</option>
          </select>
        </div>

        <div className="style-control">
          <ColorPicker selectedColor={drawColor} onColorChange={setDrawColor} />
        </div>
      </div>

      <div className="toolbar-separator" />

      <div className="toolbar-group">
        <ToolButton onClick={handleRotateLeft} title="Rotate Left 90°">
          <RotateLeftOutlined />
        </ToolButton>
        <ToolButton onClick={handleRotateRight} title="Rotate Right 90°">
          <RotateRightOutlined />
        </ToolButton>
        <div className="style-control">
          <label style={{ color: "white" }}>Rotation:</label>
          <input
            type="number"
            min="0"
            max="360"
            step="1"
            onKeyDown={handleKeyDown}
            value={rotationInput}
            onChange={handleRotationChange}
            onBlur={handleRotationBlur}
            style={{ width: "40px" }}
          />
          <span style={{ color: "white" }}>°</span>
        </div>
      </div>

      <div className="toolbar-separator" />

      <div className="toolbar-group">
        <ToolButton onClick={onSnapshot} title="Capture Snapshot">
          <PhotoCamera />
        </ToolButton>
        <ToolButton onClick={onToggleGrid} title="Toggle Grid">
          <Grid4x4Outlined />
        </ToolButton>
        <ToolButton onClick={onClearAnnotations} title="Clear All Annotations">
          <DeleteOutline />
        </ToolButton>
        <ToolButton
          onClick={onToggleFullscreen}
          title={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
        >
          {isFullscreen ? <Minimize2 /> : <Maximize2 />}
        </ToolButton>
      </div>
    </div>
  );
};

export default MicroscopeToolbar;
